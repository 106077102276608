<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { colorPalette as colors } from '../utils/enums'
import { createDiscreteApi } from 'naive-ui'

const { message } = createDiscreteApi(['message'])
// emits
const emit = defineEmits<{
  (e: 'send', text: string): void
}>()

// props
const props = defineProps({
  isLoading: Boolean,
  canSendMessage: Boolean
})

const isLoading = computed(() => props.isLoading)
const canSendMessage = computed(() => props.canSendMessage)

defineExpose({ clearInput, getChatInputValue })

// スマホ判定
const isSp = navigator.userAgent.match(/iPhone|Android.+Mobile/)
const isMac = navigator.userAgent.toLowerCase().indexOf('mac os x') !== -1 ? true : false

// input
onMounted(() => {
  window.addEventListener('keydown', keydownHandler)
  window.addEventListener('keyup', keyupHandler)
})
onUnmounted(() => {
  window.removeEventListener('keydown', keydownHandler)
  window.removeEventListener('keyup', keyupHandler)
})
function keydownHandler(e: any) {
  if (isSp) {
    return
  }
  switch (
    e.code // MDN: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
  ) {
    case 'ShiftLeft':
    case 'ShiftRight':
      if (!isMac) {
        shouldSendOnEnter.value = true
      }
      break
    case 'OSRight':
    case 'OSLeft':
    case 'MetaRight':
    case 'MetaLeft':
      if (isMac) {
        shouldSendOnEnter.value = true
      }
      break
    case 'Enter':
      handlePressEnterKey()
      break
    default:
      break
  }
}
function keyupHandler(e: any) {
  if (isSp) {
    return
  }
  switch (
    e.code // MDN: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
  ) {
    case 'ShiftLeft':
    case 'ShiftRight':
      if (!isMac) {
        shouldSendOnEnter.value = false
      }
      break
    case 'OSRight':
    case 'OSLeft':
    case 'MetaRight':
    case 'MetaLeft':
      if (isMac) {
        shouldSendOnEnter.value = false
      }
      break
    default:
      break
  }
}
const placeholderText = computed(() => {
  if (!canSendMessage.value) {
    return 'メッセージを入力できません'
  }
  return isSp
    ? 'メッセージを入力'
    : isMac
      ? 'メッセージを入力（Command + Enterで送信）'
      : 'メッセージを入力（Shift + Enterで送信）'
})
let chatInputValue = ref('')
let shouldSendOnEnter = ref(false)

function handlePressEnterKey() {
  console.log('enter', chatInputValue.value.length, isSp, shouldSendOnEnter.value)
  if (chatInputValue.value.length && !isSp && shouldSendOnEnter.value) {
    handleSend()
  }
}
function getChatInputValue() {
  return chatInputValue.value
}
function handleSend() {
  if (isLoading.value) return
  if (!canSendMessage.value) {
    message.warning('「最初からはじめる」を押して、新しい会話をお試しください')
    return
  }
  emit('send', chatInputValue.value)
}
function clearInput() {
  chatInputValue.value = ''
}

const messageInputEl = ref<HTMLInputElement | null>(null)
</script>
<template>
  <div class="chat-input" v-loading="isLoading">
    <div class="input-form">
      <textarea
        v-model="chatInputValue"
        class="user-input-textarea"
        :class="{ cannotSend: !canSendMessage }"
        ref="messageInputEl"
        :placeholder="placeholderText"
        :disabled="!canSendMessage"
      />
      <div class="send-btn" @click="handleSend()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chat-input {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  max-width: 1024px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
}
.cannotSend {
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .chat-input {
    padding: 12px 8px;
  }
}
@media (min-width: 1024px) {
  .chat-input {
    padding: 12px 0;
  }
}
.input-form {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  background-color: v-bind('colors.bg.f');
  border: 1px solid v-bind('colors.border.active');
  border-radius: 4px;
  box-sizing: border-box;
  gap: 12px;
  padding: 8px 12px;
}
.send-btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: v-bind('colors.text.base');
}
.user-input-textarea {
  width: 100%;
  height: 24px;
  resize: none;
  max-height: 30dvh;
  border: none;
  background-color: transparent;
  font-family: inherit;
  color: v-bind('colors.service.primary');
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
.user-input-textarea:focus-visible {
  outline: none; /* reset user-agent */
}
</style>
