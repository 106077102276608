import type { ChatMessageDetailTransformed } from '@/types/conversation.type'

export function sortMessages(
  messages: ChatMessageDetailTransformed[],
  sortType: 'asc' | 'desc' = 'asc'
) {
  const arr = [...messages]
  return arr.sort((a, b) => {
    return sortType == 'asc' ? a.id - b.id : b.id - a.id
  })
}
