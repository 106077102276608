<script setup lang="ts">
import { colorPalette as colors, Constants } from '../utils/enums'
import { computed, onBeforeUnmount, ref, useTemplateRef } from 'vue'
import ChatInput from '@/components/ChatInput.vue'
import { onMounted } from 'vue'
import { CANNOT_ACCEPT_NEW_MESSAGE, useConversationStore } from '@/stores/conversationStore'
import { type GetHistoryQuery } from '@/types/conversation.type'
import { useAuthStore } from '@/stores/authStore'
import { RefreshLeft, Expand, Close } from '@element-plus/icons-vue'
import { sortMessages } from '@/utils/message-util'
import ChatMessages from '@/components/ChatMessages.vue'
import { formatDateYYYYMMDDHHmm } from '@/utils/date-util'
import { createDiscreteApi } from 'naive-ui'

const { message } = createDiscreteApi(['message'])

defineProps<{
  isInspectMode: boolean
}>()
const conversationStore = useConversationStore()
const authStore = useAuthStore()
const getHistoryQuery = ref<GetHistoryQuery>({
  page: 1,
  limit: 10
})
const isSp = navigator.userAgent.match(/iPhone|Android.+Mobile/)

async function init() {
  conversationStore.clear_current_conversation()
  authStore.clear_current_user()
  const gpId = await authStore.load_gp_cookie().catch(() => null)

  // auth check
  await authStore
    .login_anonymous(gpId)
    .then(() => {
      console.log('login success')
    })
    .catch(async () => {
      // try refresh
      await authStore
        .refresh_anonymous(gpId)
        .then(() => {
          console.log('refresh and login success')
        })
        .catch(async () => {
          // login, refreshともに失敗したケース
          conversationStore.clear_conversation_id()
          await authStore.signout_anonymous()
          await authStore.signup_anonymous(gpId)
        })
    })

  // conversationId
  const cId = conversationStore.get_conversation_id()
  if (cId !== -1) {
    loadConversationDetail(cId)
  }
  conversationStore.get_history(getHistoryQuery.value)
}
function loadConversationDetail(id: number, showHistory: boolean = false) {
  conversationStore.clear_current_conversation()
  conversationStore.get_one_history(id).then(async (c) => {
    if (!c.searchQueryLoggedAt || showHistory) {
      conversationStore.set_current_conversation(c)
    } else {
      await conversationStore.clear_conversation_id()
    }
  })
}

const currentTransactions = computed(() => {
  return conversationStore.get_current_transactions
})
const currentConditionItems = computed(() => {
  return conversationStore.currentState
})

// history & pagination

const conversationHistory = computed(() => conversationStore.conversations)
const currentPage = computed({
  get: () => getHistoryQuery.value.page,
  set: (val) => (getHistoryQuery.value.page = val)
})
function changePage(v: number): void {
  getHistoryQuery.value.page = v
  conversationStore.get_history(getHistoryQuery.value)
}
const totalPages = computed(() => {
  return conversationStore.conversations_stats?.totalPages || 1
})

// init
init()
onMounted(() => {
  scrollToBottom()
})

// chatInput, Loading, Abort
const chatInput = ref<InstanceType<typeof ChatInput> | null>(null)
const currentAc = ref<AbortController | null>(null)
const isLoading = computed(() => currentAc.value !== null)
const handleCancelRequest = () => {
  if (currentAc.value) {
    currentAc.value.abort()
  }
  currentAc.value = null
}

async function handleSend(text: string) {
  if (isLoading.value) return
  if (text.length === 0) {
    return
  }
  currentAc.value = new AbortController()
  // set or create conversationId
  const conversationId = await (() => {
    if (conversationStore.currentConversation) {
      return conversationStore.currentConversation.id
    } else {
      return conversationStore.start(text)
    }
  })()

  // routingConcierge
  let canSendWithNewChat = false
  const res = await conversationStore.routingConcierge(text, conversationId).catch((err) => {
    canSendWithNewChat = err.response?.data.message === CANNOT_ACCEPT_NEW_MESSAGE
    handleCancelRequest()
  })

  if (!res || res.moderationFlagged) {
    // moderationFlagged or UnexpectedError
    handleCancelRequest()
    clearInputAndScroll()
    if (canSendWithNewChat) {
      message.warning('長時間操作がなかったので、新しい会話を開始します')
      await handleSendWithNewChat(text)
    }
    return
  }
  // 自分のメッセージとLoadingを見せる
  scrollToBottom()

  currentAc.value = new AbortController()
  switch (res.route) {
    case 'ShoppingAssistant':
      conversationStore
        .chatShoppingAssistant(conversationId, res.tx.id)
        .then(() => {
          clearInputAndScroll()
        })
        .catch(() => {
          handleCancelRequest()
        })
      break
    case 'SmallTalkPartner':
    case 'CustomerSupportOperator':
    case 'Other':
      conversationStore
        .postMinorPipeline(conversationId, res.tx.id, res.route)
        .then(() => {
          clearInputAndScroll()
        })
        .catch(() => {
          handleCancelRequest()
        })
      break
    default:
      console.log('invalid')
      break
  }
}
async function handleSendWithNewChat(text: string) {
  await newChat()
  await handleSend(text)
}
async function handleRetry() {
  const txLength = currentTransactions.value.length
  if (txLength === 0) {
    console.error('no transaction')
    return
  }
  const messages = currentTransactions.value[txLength - 1].messages
  if (messages.length === 0) {
    console.error('no message')
    return
  }
  const userMessage = sortMessages(messages, 'desc').filter((x) => x.role == 'user')[0]
  if (!userMessage) {
    console.error('no user message')
    return
  }
  await handleSend(userMessage.text)
}
function canRetryMessage() {
  if (isLoading.value) {
    return false
  }
  const txLength = currentTransactions.value.length
  if (txLength === 0) {
    return false
  }
  const assistantLastMessage = sortMessages(
    currentTransactions.value[txLength - 1].messages,
    'desc'
  ).filter((x) => x.role == 'assistant')[0]
  if (!assistantLastMessage) {
    return false
  }
  return !!assistantLastMessage.data?.retryable
}
function clearInputAndScroll() {
  chatInput.value?.clearInput()
  currentAc.value = null
  scrollToNewMessage()
}
const currentConversationIdLabel = computed(() => {
  if (conversationStore.currentConversation) {
    return '(会話ID) ' + conversationStore.currentConversation.id
  } else {
    return '(会話ID) ... '
  }
})
function scrollToBottom() {
  // for: 初期化, Loading表示
  const wrapperDivs = document.querySelectorAll('.main-area')
  if (wrapperDivs.length) {
    wrapperDivs[0].scrollTop = wrapperDivs[0].scrollHeight
  }
}
function scrollToNewMessage() {
  // 最新メッセージの頭が画面内に収まるようにする
  const messageElements = document.querySelectorAll('.message.aiwebchat')
  if (messageElements.length) {
    messageElements[messageElements.length - 1].scrollIntoView({
      behavior: 'instant',
      block: 'start'
    })
    // fixed header分 (48 + 40 + 余白) 動かす
    const wrapperDivs = document.querySelectorAll('.main-area')
    if (wrapperDivs.length) {
      wrapperDivs[0].scrollTop -= 96
    }
  }
}

// transit
async function newChat() {
  menuOpen.value = false
  await conversationStore.clear_conversation_id()
  await init()
}
function openConsole() {
  const cId = conversationStore.currentConversation?.id
  if (!cId) {
    return
  }
  // in a new tab
  window.open(
    Constants.BASE_URL_CONSOLE + '/#/monitoring?conversationId=' + cId,
    '_blank',
    'noopener,noreferrer'
  )
}

// keyboard resize
const chatInputRef = useTemplateRef('chatInput')
function adjustFooterPosition() {
  if (chatInputRef.value) {
    chatInputRef.value.$el.style.bottom = '0px'
  }
  const el = document.querySelector('.header-wrapper.aiwebchat')
  if (el) {
    ;(el as HTMLElement).style.top = '0px'
  }
}
onMounted(() => {
  window.addEventListener('resize', adjustFooterPosition)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustFooterPosition)
})

// hamburger menu
const showMenuButton = computed(() => conversationHistory.value.length > 0)
const menuOpen = ref(false)
const isLoadingConversation = ref(false)

const openConversation = (id: number) => {
  if (isLoadingConversation.value) {
    return
  }
  isLoadingConversation.value = true
  loadConversationDetail(id, true)
  menuOpen.value = false
  isLoadingConversation.value = false
}
</script>

<template>
  <div class="search-wrapper">
    <div class="header-wrapper aiwebchat">
      <div class="sp-wrapper">
        <div class="header">
          <div class="header-left">
            <div class="menu-button" v-show="showMenuButton" @click="menuOpen = !menuOpen">
              <el-icon :size="24">
                <template v-if="menuOpen">
                  <Close color="black" />
                </template>
                <template v-else>
                  <Expand color="black" />
                </template>
              </el-icon>
            </div>
            <template v-if="isInspectMode">
              <div class="conversation-id">{{ currentConversationIdLabel }}</div>
              <el-button
                v-if="!isSp"
                style="margin-left: 12px"
                :disabled="!conversationStore.currentConversation"
                @click="openConsole()"
                >会話分析を開く</el-button
              >
            </template>
            <el-button style="margin-left: 12px" @click="newChat()">最初からはじめる</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="sp-wrapper">
      <div class="main-area">
        <template v-if="menuOpen">
          <div class="menu-wrapper">
            <div class="menu-header">
              <div class="menu-header-title">会話履歴</div>
            </div>
            <div
              class="menu-item"
              v-for="row in conversationHistory"
              :key="row.id"
              @click="openConversation(row.id)"
            >
              <div class="menu-item-date">
                {{ `${formatDateYYYYMMDDHHmm(row.createdAt)} 付近の会話` }}
              </div>
            </div>
            <!-- ページネーション -->
            <div class="pagination-wrapper">
              <el-pagination
                class="pagination"
                :current-page="currentPage"
                :hide-on-single-page="true"
                :total="totalPages"
                :page-count="totalPages"
                @current-change="changePage"
                layout="prev, pager, next"
              />
            </div>
          </div>
        </template>
        <div class="search-conditions-wrapper">
          <div class="search-icon">
            <img
              src="../assets/images/ic-search.png"
              srcset="../assets/images/ic-search@2x.png 2x, ../assets/images/ic-search@3x.png 3x"
            />
          </div>
          <div class="search-condition-item" v-for="(row, i) in currentConditionItems" :key="i">
            <span v-if="row.key.length">{{ row.key }}: </span>{{ row.label }}
          </div>
          <div v-if="!currentConditionItems.length" class="placeholder">
            AIが提案に使った条件が表示されます
          </div>
        </div>
        <div class="disclaimer-flex-container">
          <div class="disclaimer-container">
            <img src="../assets/images/instruction.png" />
          </div>
        </div>
        <!-- 自動のファーストメッセージ -->
        <div class="message">
          <div class="assistant-message-container">
            <div class="assistant-icon">
              <img src="../assets/images/ic-chaticonbear.jpg" />
            </div>
            <div class="assistant-message-text">
              <div class="assistant-message-text-body">
                こんにちわ！<br />どんなカラコンをお探しでしょうか？？
              </div>
            </div>
          </div>
        </div>
        <ChatMessages :transactions="currentTransactions" />
        <LoadingAnimation :show="isLoading" />

        <!-- retry button -->
        <div v-if="canRetryMessage()" class="retry-button-wrapper">
          <el-button class="retry-button" :loading="isLoading" @click="handleRetry()">
            <el-icon style="margin-right: 4px" :size="16"><RefreshLeft color="black" /></el-icon>
            再送信する
          </el-button>
        </div>
        <!-- <LoadingWithAbort v-if="isLoading" @cancel="handleCancelRequest" /> -->
        <ChatInput
          :isLoading="isLoading"
          :canSendMessage="!conversationStore?.currentConversation?.searchQueryLoggedAt"
          ref="chatInput"
          @send="handleSend($event)"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.search-wrapper {
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  /* background-color: v-bind('colors.bg.base'); */
}
.sp-wrapper {
  width: 100%;
  max-width: 1024px;
}
.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background-color: v-bind('colors.bg.f');
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid v-bind('colors.border.divider');
}
.header {
  height: 100%;
  line-height: 48px;
  padding: 0 4px;
  display: flex;
  justify-content: space-between;
}
.header-left {
  display: flex;
  align-items: center;
  .btn {
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    padding: 0 4px;
    .icon {
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 2px;
    }
  }
  .conversation-id {
    font-size: 12px;
    font-weight: bold;
    width: auto;
    color: v-bind('colors.text.lighter');
    margin-left: 8px;
  }
}
.main-area {
  width: 100%;
  position: relative;
  padding: 72px 0 80px;
  height: calc(100svh - 48px - 12px); /* chatInput, padding+buffer */
  height: 100svh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.menu-wrapper {
  position: fixed;
  top: 48px;
  width: 250px;
  height: 100%;
  background-color: v-bind('colors.bg.f');
  z-index: 1000;
  /* スクロール */
  overflow-y: scroll;
  .menu-header {
    height: 48px;
    padding: 0 12px;
    .menu-header-title {
      line-height: 48px;
      font-size: 16px;
      font-weight: bold;
      color: v-bind('colors.text.base');
    }
  }
  .menu-item {
    height: 48px;
    padding: 0 12px;
    display: flex;
    align-items: center;

    /* hover */
    &:hover {
      background-color: v-bind('colors.bg.base');
      cursor: pointer;
    }

    .menu-item-date {
      font-size: 12px;
      color: v-bind('colors.text.lighter');
    }
  }
}
.disclaimer-flex-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  height: auto;
  .disclaimer-container {
    /* width: media */
    width: 100%;
    padding-top: 8px;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      max-width: 600px;
    }
  }
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
/* search conditions */
.search-conditions-wrapper {
  position: fixed;
  top: 48px;
  width: 100%;
  padding: 0 10px;
  height: 40px;
  display: flex;
  overflow-x: scroll;
  gap: 8px;
  background-color: v-bind('colors.bg.base');
  z-index: 100;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.search-condition-item {
  display: inline-block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}
.search-conditions-wrapper > .placeholder {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.search-conditions-edit {
  display: flex;
  align-items: center;
  margin-left: 4px;
  gap: 4px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
  cursor: pointer;
}
.search-icon {
  width: 20px;
  height: 20px;
  margin-right: -2px;
}
.retry-button-wrapper {
  width: 100%;
  justify-content: end;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 12px;
  .retry-button {
    width: 120px;
    height: 48px;
  }
}
.divider-text {
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.load-more-button-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.assistant-message-container {
  display: flex;
  margin-left: 8px;
  margin-right: 56px;
  vertical-align: middle;
  margin-top: 12px;
  height: auto;
  .assistant-icon {
    width: 44px;
    height: 44px;
    margin-top: 4px;
    margin-right: 8px;
    img {
      width: 44px;
      height: 44px;
      vertical-align: middle;
    }
  }
  .assistant-message-text {
    display: flex;
    flex-direction: column;
    background-color: v-bind('colors.bg.lighterPale');
    border-radius: 8px;
    padding: 16px 20px 16px 16px;
    gap: 12px;
    .assistant-message-text-body {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      color: v-bind('colors.text.base');
      white-space: normal;
    }
  }
}
@media (max-width: 1024px) {
  .disclaimer-container {
    width: 95%;
  }
}
@media (min-width: 1024px) {
  .disclaimer-container {
    width: 80%;
  }
}
.menu-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
