<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import img01 from '../assets/images/loading/01.png'
import img02 from '../assets/images/loading/02.png'
import img03 from '../assets/images/loading/03.png'
import img04 from '../assets/images/loading/04.png'
import img05 from '../assets/images/loading/05.png'
import img06 from '../assets/images/loading/06.png'
import img07 from '../assets/images/loading/07.png'
import img08 from '../assets/images/loading/08.png'
const intervalId = ref<number | null>(null)
const isPreLoaded = ref(false)

const props = defineProps<{
  show: boolean
}>()
onMounted(() => {
  const keyframes = `
    @keyframes loading-animation {
      0% {
        background-image: url(${img01});
      }
      12.5% {
        background-image: url(${img02});
      }
      25% {
        background-image: url(${img03});
      }
      37.5% {
        background-image: url(${img04});
      }
      50% {
        background-image: url(${img05});
      }
      62.5% {
        background-image: url(${img06});
      }
      75% {
        background-image: url(${img07});
      }
      100% {
        background-image: url(${img08});
      }
    }`
  const s = (() => {
    // クロスオリジン制約を受けないようにインラインスタイルシートを作成
    const style = document.createElement('style')
    document.head.appendChild(style)
    return style.sheet
  })()
  if (s) {
    s.insertRule(keyframes, s.cssRules.length)
  }
  setTimeout(() => {
    isPreLoaded.value = true
    if (props.show) {
      startProgressbar()
    }
  }, 300) // small delay to avoid flickering
})
onBeforeUnmount(() => {
  if (intervalId.value) {
    window.clearInterval(intervalId.value)
  }
})

const progressBarVisible = ref(false)
const percentage = ref(0)
const MAX_PERCENTAGE = 80 // 15sくらいprogressbarが動く
const duration = computed(() => {
  return Math.floor(percentage.value / 10)
})
watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      startProgressbar()
    } else {
      finishProgressbar()
    }
  }
)
const startProgressbar = () => {
  percentage.value = 0
  progressBarVisible.value = true
  setInterval(() => {
    if (percentage.value < MAX_PERCENTAGE) {
      percentage.value += 4
    }
  }, 750)
}
const finishProgressbar = () => {
  percentage.value = 100
  setTimeout(() => {
    progressBarVisible.value = false
  }, 1000)
}
</script>
<template>
  <div class="loader-wrapper">
    <div v-show="progressBarVisible" class="loader-container">
      <div v-show="isPreLoaded" class="loader" :class="{ 'is-loaded': isPreLoaded }" />
      <el-progress
        :percentage="percentage"
        :show-text="false"
        :stroke-width="10"
        striped
        striped-flow
        :duration="duration"
        style="width: 200px; margin-top: 12px"
      />
    </div>
  </div>
</template>
<style scoped>
.loader-wrapper {
  background-image: url('@/assets/images/loading/01.png'), url('@/assets/images/loading/02.png'),
    url('@/assets/images/loading/03.png'), url('@/assets/images/loading/04.png'),
    url('@/assets/images/loading/05.png'), url('@/assets/images/loading/06.png'),
    url('@/assets/images/loading/07.png'), url('@/assets/images/loading/08.png');
  background-size: 0;
}
.loader-container {
  width: 100%;
  height: 140px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 120px;
  height: 120px;
  background-size: cover;
  will-change: background-image;
  animation: loading-animation 1.2s steps(8) infinite;
  animation-play-state: paused;
}
.loader.is-loaded {
  animation-play-state: running;
}
</style>
