<script setup lang="ts">
import { colorPalette as colors } from '../utils/enums'
import { computed } from 'vue'
import {
  type ChatTransactionDetail,
  type ChatMessageDetailTransformed,
  type ChatTransaction
} from '@/types/conversation.type'
import { useConversationStore } from '@/stores/conversationStore'
import { sortMessages } from '@/utils/message-util'

// props
defineProps({
  transactions: Array<ChatTransactionDetail>
})

const conversationStore = useConversationStore()

// userRating
const USER_RATING_DEFAULT = -1
const userRatingLabels = {
  1: '最悪',
  2: '残念',
  3: '普通',
  4: '良い',
  5: '最高'
}
const alreadyRatedTxIds = computed(() => {
  return conversationStore.alreadyRatedTxIds
})
const hasRecommendItems = (m: ChatMessageDetailTransformed) => {
  return m.additionalContents.some((x) => x.messageType == 'flex')
}
const handleClickUserRating = (tx: ChatTransaction) => {
  if (!conversationStore.currentConversation || tx.userRating == USER_RATING_DEFAULT) {
    return
  }
  const cId = conversationStore.currentConversation?.id
  if (!cId) {
    return
  }
  setTimeout(() => {
    conversationStore.update_user_rating(cId, tx.id, tx.userRating)
  }, 200)
}
function openItemPage(chatMessageId: number, url: string) {
  conversationStore.postClickLog(chatMessageId, url)
  // in a new tab
  window.open(url, '_blank', 'noopener,noreferrer')
}
function messageFormatter(text: string) {
  return text.replaceAll('\n', '<br/>')
}
</script>
<template>
  <div v-for="tx in transactions" :key="tx.id">
    <div v-for="m in sortMessages(tx.messages)" :key="m.id" class="message aiwebchat">
      <div v-if="m.role == 'system'" class="system-message">
        {{ m.text }}
      </div>
      <div v-if="m.role == 'user'" class="user-message-container">
        <div class="user-message-text" v-html="messageFormatter(m.text)" />
      </div>
      <div v-if="m.role == 'assistant'" class="assistant-message-container">
        <div class="assistant-icon">
          <img src="../assets/images/ic-chaticonbear.jpg" />
        </div>
        <div class="assistant-message-text">
          <div class="assistant-message-text-body" v-html="messageFormatter(m.text)" />
          <div v-for="(content, i) in m.additionalContents" :key="i">
            <div
              v-if="content.messageType == 'text'"
              class="additional-references"
              v-html="content.text"
            />
            <template v-if="content.messageType == 'flex'">
              <div v-if="content.title" class="product-grid-title">{{ content.title }}</div>
              <div class="product-grid">
                <div
                  v-for="(item, j) in content.items"
                  :key="j"
                  class="product-grid-item"
                  @click="openItemPage(m.id, item.link)"
                >
                  <img :src="item.imageUrl" />
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="!alreadyRatedTxIds.includes(tx.id) && hasRecommendItems(m)"
            class="user-rating"
          >
            <div class="assistant-message-text-body prompt-user-rating">お役に立てましたか？</div>
            <div class="user-rating-form">
              <el-radio-group
                v-model="tx.userRating"
                size="small"
                @change="handleClickUserRating(tx)"
              >
                <el-radio-button
                  v-for="item in [1, 2, 3, 4, 5]"
                  :key="item"
                  :label="userRatingLabels[item as keyof typeof userRatingLabels]"
                  :value="item"
                />
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.assistant-message-container {
  display: flex;
  margin-left: 8px;
  margin-right: 56px;
  vertical-align: middle;
  margin-top: 12px;
  height: auto;
  .assistant-icon {
    width: 44px;
    height: 44px;
    margin-top: 4px;
    margin-right: 8px;
    img {
      width: 44px;
      height: 44px;
      vertical-align: middle;
    }
  }
  .assistant-message-text {
    display: flex;
    flex-direction: column;
    background-color: v-bind('colors.bg.lighterPale');
    border-radius: 8px;
    padding: 16px 20px 16px 16px;
    gap: 12px;
    .assistant-message-text-title {
      font-size: 20px;
      font-weight: bold;
      color: v-bind('colors.text.black');
      margin-bottom: 12px;
    }
    .assistant-message-text-body {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      color: v-bind('colors.text.base');
      white-space: normal;
      &.prompt-user-rating {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 4px;
      }
    }
    .additional-references {
      * {
        font-size: 12px;
        font-weight: bold;
      }
      color: v-bind('colors.text.lighter');
    }
    .user-rating {
      margin-top: 8px;
    }
  }
}

.system-message {
  margin-left: 60px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
  vertical-align: middle;
  margin-top: 12px;
  height: auto;
  white-space: normal;
}
.user-message-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
  margin-right: 8px;
  margin-left: 112px;
  height: auto;
}
.user-message-text {
  width: auto;
  background-color: v-bind('colors.bg.accentPale');
  border-radius: 8px;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: v-bind('colors.text.base');
  vertical-align: middle;
  white-space: normal;
}

/* product grid */
.product-grid-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}
.product-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 95%;
  height: auto;
}
.product-grid-item {
  width: 8%;
  min-width: 100px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (max-width: 1024px) {
  .assistant-message-container {
    .assistant-message-text {
      padding: 16px 8px 16px 12px;
    }
  }
  .assistant-message-container {
    margin-right: 32px;
  }
  .user-message-container {
    .user-message-text {
      padding: 16px 8px 16px 12px;
    }
  }
}
</style>
