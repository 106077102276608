export function formatDateTimeMMDDHHmm(date: Date): string {
  const d = new Date(date) // 日付を取得
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')

  const hours = d.getHours().toString().padStart(2, '0')
  const minutes = d.getMinutes().toString().padStart(2, '0')
  return `${month}/${day} ${hours}:${minutes}`
}

export function formatDateYYYYMMDDHHmm(date: Date): string {
  const d = new Date(date)
  const year = d.getFullYear()
  const MMDDHHmm = formatDateTimeMMDDHHmm(d)
  return `${year}/${MMDDHHmm}`
}
