import { defineStore } from 'pinia'
import { type _AxiosError } from './api'
import { type OpenAIStatusRes, OpenAIStatus } from '@/types/status.type'

interface State {
  openaiStatus: OpenAIStatus
}
export const useStatusStore = defineStore('statusStore', {
  state: (): State => ({
    openaiStatus: 'none'
  }),
  actions: {
    async fetchOpenAIStatus() {
      return new Promise<void>((resolve, reject) => {
        fetch('https://status.openai.com/api/v2/status.json')
          .then((res) => res.json())
          .then((data: OpenAIStatusRes) => {
            this.openaiStatus = data.status.indicator
            resolve()
          })
          .catch((error: _AxiosError) => {
            console.error(error)
            reject(error)
          })
      })
    }
  }
})
